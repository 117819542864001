/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconArrowRight = React.forwardRef<SVGSVGElement, Props>(
  (
    { fill = "none", stroke = "currentColor", weight = "normal", ...props },
    ref,
  ) => (
    <svg
      viewBox="0 0 24 24"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 24px;
      `}
      {...props}
    >
      <path
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight === "normal" ? 1.5 : 1}
        d="M5.5.75l10.72 10.72a.749.749 0 0 1 .001 1.059l-.001.001L5.5 23.25"
      />
    </svg>
  ),
);
