/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconDoubleArrowRight = React.forwardRef<SVGSVGElement, Props>(
  (
    { fill = "none", stroke = "currentColor", weight = "normal", ...props },
    ref,
  ) => (
    <svg
      viewBox="0 0 24 25"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 25px;
      `}
      {...props}
    >
      <g
        fill={fill}
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight === "normal" ? 1.5 : 1}
      >
        <path d="M12.153 23.307l10.629-10.629c.29-.29.29-.76 0-1.05h0L12.153.998M1 23.307l10.628-10.629c.29-.29.29-.76.001-1.05h0L1 .998" />
      </g>
    </svg>
  ),
);
